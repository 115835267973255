import React from "react"
import { message, Row } from "antd"
import CustomBreadcrumb from "../../components/commonBreadcrumb"
import Template from "../../layouts/base"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import withAuth from "../../components/withAuthHoc"
import PermissionsForm from "../../components/permissions/permissionsForm"
import authFetch from "../../services/network"
import { getPermissionsValue } from "../../services/auth/permissions/permissions"
import { callbackRolesErrorMessages } from "../../services/roles/rolesRequestErrorMessages"

class RoleNew extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      role: this._initRole(),
      permissionsModel: getPermissionsValue(),
      ingests: null,
      processing: false,
    }
    this.createRole = this.createRole.bind(this)
  }

  componentDidMount() {
    authFetch(`${process.env.GATSBY_CONF_API_URL}/ingests`)
      .then(response => {
        response.json().then(ingests => {
          if (200 !== response.status) {
            message.error(
              this.props.intl.formatMessage({
                id: "page-ingest-get-list-error-text",
              })
            )
          } else {
            const ingestsNames = [
              ...new Map(ingests.map(item => [item.alias, item])).values(),
            ].map(ingest => ingest.alias)
            this.setState({ ingests: ingestsNames })
          }
        })
      })
      .catch(reason => {
        message.error(
          this.props.intl.formatMessage({
            id: "page-ingest-get-list-error-text",
          })
        )
        throw new Error(`[ERROR] Retrieving ingest list: ${reason}`)
      })
  }

  goBack() {
    navigate("/role")
  }

  _initRole() {
    return {
      name: "",
      description: "",
      ingests: [],
      permissions: [],
    }
  }

  createRole(role) {
    this.setState({ processing: true })
    authFetch(`${process.env.GATSBY_CONF_API_URL}/roles`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: role,
    })
      .then(response => {
        this.setState({ processing: false })
        if (201 !== response.status) {
          callbackRolesErrorMessages(response, "POST").then(text => {
            message.error(this.props.intl.formatMessage({ id: text }))
          })
        } else {
          message.success(
            this.props.intl.formatMessage({ id: "page-role-create-ok-text" })
          )
          navigate(`/role/${role.name}`)
        }
      })
      .catch(error => {
        this.setState({ processing: false })
        callbackRolesErrorMessages(null, "POST").then(text => {
          message.error(this.props.intl.formatMessage({ id: text }))
        })
        throw new Error(`[ERROR] Creating role: ${error}`)
      })
  }

  render() {
    return (
      <Template selected={["user", "user-new"]}>
        <CustomBreadcrumb
          crumbs={[
            {
              label: this.props.intl.formatMessage({
                id: "menu-role-managment",
              }),
              link: "/role",
            },
            this.props.intl.formatMessage({ id: "menu-role-new" }),
          ]}
        />
        {this.state.role ? (
          <Row>
            <PermissionsForm
              role={this.state.role}
              permissionsModel={this.state.permissionsModel}
              isEditView={false}
              allIngests={this.state.ingests}
              onCancel={this.goBack}
              onSubmit={this.createRole}
              processing={this.state.processing}
            />
          </Row>
        ) : (
          <Row
            style={{
              height: "200px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin size="large" />
          </Row>
        )}
      </Template>
    )
  }
}

export default injectIntl(withAuth(RoleNew))
