import { message } from "antd"

export function callbackRolesErrorMessages(response = null, method = null) {
  return new Promise((ok, ko) => {
    if (response && response.status) {
      switch (response.status) {
        case 409:
          ok("page-role-request-409")
        case 403:
          ok("page-role-request-403")
        case 404:
          ok("page-role-request-404")
        case 500:
          ok("error-500")
        case 400:
          response
            .json()
            .then(body => {
              switch (body.message) {
                case "ROLE_WRITE_BAD_REQUEST_INVALID_PERMISSIONS_ERROR":
                  switch (method) {
                    case "POST":
                      ok("page-role-request-invalid-permissions-post-400")
                    case "PUT":
                      ok("page-role-request-invalid-permissions-put-400")
                    default:
                      ok(_getDefaultMessage(method))
                  }
                case "ROLE_WRITE_BAD_REQUEST_INVALID_INGESTS_ERROR":
                  switch (method) {
                    case "POST":
                      ok("page-role-request-invalid-ingests-post-400")
                    case "PUT":
                      ok("page-role-request-invalid-ingests-put-400")
                    default:
                      ok(_getDefaultMessage(method))
                  }
                default:
                  ok(_getDefaultMessage(method))
              }
            })
            .catch(() => {
              ok(_getDefaultMessage(method))
            })
          break
        default:
          ok(_getDefaultMessage(method))
      }
    } else {
      ok(_getDefaultMessage(method))
    }
  })
}

function _getDefaultMessage(method) {
  switch (method) {
    case "POST":
      return "page-role-request-invalid-POST"
    case "PUT":
      return "page-role-request-invalid-PUT"
    case "GET":
      return "page-role-request-invalid-GET"
    case "DELETE":
      return "page-role-request-invalid-DELETE"
    case "LIST":
      return "page-role-request-invalid-LIST"

    default:
      return "error-500"
  }
}
