import React from "react"
import {
  Button,
  Checkbox,
  Col,
  Form,
  Icon,
  message,
  Row,
  Select,
  Tooltip,
} from "antd"
import { injectIntl } from "gatsby-plugin-intl"
import { FormControl, InputLabel, OutlinedInput } from "@material-ui/core"

const { Option } = Select

const FORM_NAME = "role_form"

class PermissionsForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentIngestsSelected: this.props.role.ingests
        ? this.props.role.ingests
        : [],
      disabledOptions: false,
    }
    this.getCheckboxToPermissionValue = this.getCheckboxToPermissionValue.bind(
      this
    )
    this.selectIngestToRole = this.selectIngestToRole.bind(this)
    this._onCheckboxChange = this._onCheckboxChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this._validateRequiredPermissionsCheckbox = this._validateRequiredPermissionsCheckbox.bind(
      this
    )
    this.onChangeIngestToRole = this.onChangeIngestToRole.bind(this)
    this._setDisabledCheckBox = this._setDisabledCheckBox.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.handleCancelEmitter = this.handleCancelEmitter.bind(this)
    this._validateRequiredPermissionsCheckbox()
  }

  _onCheckboxChange(e) {
    const { id, checked } = e && e.target
    const nameWithoutFormName = id.replace(FORM_NAME + "_", "")
    const fieldUpdate = { [nameWithoutFormName]: checked }
    this.props.form.setFieldsValue(fieldUpdate)
    this.props.permissionsModel.forEach(permissionList => {
      permissionList.permissions.forEach(item => {
        if (item.name === nameWithoutFormName) {
          item.value = checked
        }
      })
    })
    this._validateRequiredPermissionsCheckbox()
  }

  handleInputChange() {
    const {
      target: { name: n, value: v },
    } = event
    this.props.role[n] = v
  }

  selectIngestToRole(value) {
    if (value === "*") {
      this.props.role.ingests = ["*"]
      this.setState({
        currentIngestsSelected: [value],
        disabledOptions: true,
      })
    } else if (value === "select-available") {
      this.props.role.ingests = this.props.allIngests
      this.setState({
        currentIngestsSelected: this.props.allIngests,
        disabledOptions: false,
      })
    }
  }

  onChangeIngestToRole(value) {
    this.props.role.ingests = value
    this.setState({
      currentIngestsSelected: value,
      disabledOptions: false,
    })
  }

  getCheckboxToPermissionValue(permission, title = null) {
    const { getFieldDecorator } = this.props.form
    return permission.name ? (
      <Form.Item style={{ marginBottom: 0 }}>
        {getFieldDecorator(permission.name, {
          initialValue: permission.value,
        })(
          <Checkbox
            style={
              !title
                ? {
                    width: 16,
                    height: 16,
                  }
                : null
            }
            id={permission.name}
            disabled={permission.disabled}
            checked={permission.value}
            onChange={this._onCheckboxChange}
          >
            {title ? this.props.intl.formatMessage({ id: title }) : null}
            {permission.hint && (
              <Tooltip
                title={this.props.intl.formatMessage({ id: permission.hint })}
              >
                &nbsp;
                <Icon type="info-circle" size="large" />
              </Tooltip>
            )}
          </Checkbox>
        )}
      </Form.Item>
    ) : null
  }

  handleCancelEmitter() {
    const { onCancel } = this.props
    if (typeof onCancel === "function") {
      onCancel()
    }
  }

  _validateRequiredPermissionsCheckbox() {
    let isValid = true
    const required = this.props.permissionsModel
      .map(permission => permission.permissions.filter(item => item.value))
      .flat(1)
      .map(b => (b.required ? b.required : []))
      .flat(1)
    this._setDisabledCheckBox(false)
    if (required && required.length > 0) {
      const unique = [...new Set(required)]
      unique.forEach(requiredPermission => {
        const per = this.props.permissionsModel
          .map(permission =>
            permission.permissions.find(
              item => item.name === requiredPermission
            )
          )
          .find(subItem => subItem && subItem.name === requiredPermission)
        if (!per.value) {
          per.value = true
          isValid = true
          const fieldUpdate = { [per.name]: true }
          this.props.form.setFieldsValue(fieldUpdate)
        }
        per.disabled = true
      })
    }
    return isValid
  }

  _setDisabledCheckBox(disabled) {
    this.props.permissionsModel.forEach(item => {
      item.permissions.forEach(permission => {
        permission.disabled = disabled
      })
    })
  }

  submitForm() {
    this.props.form.validateFields(err => {
      if (err) {
        return message.error(
          this.props.intl.formatMessage({
            id: "page-user-form-generic-error-text",
          })
        )
      } else {
        const { onSubmit } = this.props
        if (
          typeof onSubmit === "function" &&
          this._validateRequiredPermissionsCheckbox()
        ) {
          const role = this._parseRoleToSubmit()
          onSubmit(role)
          return
        }
        return message.error(
          this.props.intl.formatMessage({
            id: "page-user-form-generic-error-text",
          })
        )
      }
    })
  }

  _parseRoleToSubmit() {
    return {
      name: this.props.role.name,
      description: this.props.role.description,
      ingests: this.state.currentIngestsSelected,
      permissions: this._getParsePermissionsToSubmit(),
    }
  }

  _getParsePermissionsToSubmit() {
    const haveAllPermissions =
      this.props.permissionsModel
        .map(permission =>
          permission.permissions.filter(
            item => !item.value && item.value !== null
          )
        )
        .flat(1).length === 0
    if (haveAllPermissions) {
      return ["*"]
    } else {
      const result = []
      this.props.permissionsModel
        .map(permission => permission.permissions.filter(item => item.value))
        .flat(1)
        .forEach(b => {
          if (b.permissions) {
            b.permissions.forEach(p => result.push(p))
          } else {
            result.push(b.name)
          }
        })
      return result
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form
    return (
      <div>
        <Row style={{ display: "flex", alignItems: "center" }}>
          <Col span={18} className="knolar-intro">
            {this.props.intl.formatMessage({
              id: this.props.isEditView
                ? "menu-role-update"
                : "menu-role-new-button",
            })}
          </Col>
          <Col span={6} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                className="knolar-button back"
                style={{ marginRight: 15 }}
                onClick={this.handleCancelEmitter}
              >
                {this.props.intl.formatMessage({ id: "cancel" })}
              </Button>
            </Form.Item>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                type="primary"
                onClick={this.submitForm}
                className="knolar-button"
                loading={this.props.processing}
              >
                {this.props.isEditView
                  ? this.props.intl.formatMessage({ id: "menu-role-update" })
                  : this.props.intl.formatMessage({ id: "menu-role-new" })}
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Form onSubmit={this.submitForm}>
          <Row className="kpi-content" style={{ padding: "20px 0 0 20px" }}>
            <Col md={6}>
              <Form.Item>
                <FormControl variant="outlined" className="form-item" fullWidth>
                  <InputLabel
                    className={`knolar-input-label ${
                      this.props.isEditView ? "disabled" : ""
                    }`}
                    htmlFor="role"
                  >
                    {this.props.intl.formatMessage({
                      id: "page-role-component-form-name",
                    })}
                  </InputLabel>
                  {getFieldDecorator("name", {
                    initialValue: this.props.role.name,
                    rules: [
                      {
                        required: true,
                        message: this.props.intl.formatMessage({
                          id: "form-required-field",
                        }),
                      },
                      {
                        validator: (rule, value, cb) => {
                          try {
                            if (/\s/.test(value)) {
                              throw new Error(
                                this.props.intl.formatMessage({
                                  id: "form-rule-no-whitespaces-error-text",
                                })
                              )
                            } else {
                              cb()
                            }
                          } catch (err) {
                            cb(err)
                          }
                        },
                      },
                    ],
                  })(
                    <OutlinedInput
                      maxLength={127}
                      className="knolar-user-input"
                      name="name"
                      onChange={this.handleInputChange}
                      disabled={this.props.isEditView}
                      validatestatus="error"
                      placeholder={this.props.intl.formatMessage({
                        id: "page-role-component-form-name-placeholder",
                      })}
                      fullWidth
                    />
                  )}
                </FormControl>
              </Form.Item>
            </Col>
            <Col md={16} offset={1}>
              <Form.Item>
                <FormControl variant="outlined" className="form-item" fullWidth>
                  <InputLabel className="knolar-input-label" htmlFor="role">
                    {this.props.intl.formatMessage({
                      id: "description",
                    })}
                  </InputLabel>
                  {getFieldDecorator("description", {
                    initialValue: this.props.role.description,
                  })(
                    <OutlinedInput
                      className="knolar-user-input"
                      maxLength={2048}
                      name="description"
                      onChange={this.handleInputChange}
                      placeholder={this.props.intl.formatMessage({
                        id: "page-role-component-form-description-placeholder",
                      })}
                      fullWidth
                    />
                  )}
                </FormControl>
              </Form.Item>
            </Col>
          </Row>
          <Row className="kpi-content" style={{ padding: "0px" }}>
            <table>
              <thead className="kpi-title" style={{ background: "#F2F2F2" }}>
                <tr style={{ textAlign: "center" }}>
                  <th style={{ textAlign: "left", padding: "15px 50px" }}>
                    {this.props.intl.formatMessage({
                      id: "page-role-component-table-operation",
                    })}
                  </th>
                  <th style={{ height: 30 }}>
                    {this.props.intl.formatMessage({
                      id: "page-role-component-table-permission-read",
                    })}
                  </th>
                  <th style={{ height: 30 }}>
                    {this.props.intl.formatMessage({
                      id: "page-role-component-table-permission-write",
                    })}
                  </th>
                  <th style={{ height: 30 }}>
                    {this.props.intl.formatMessage({
                      id: "page-role-component-table-permission-delete",
                    })}
                  </th>
                  <th style={{ height: 30 }}>
                    {this.props.intl.formatMessage({
                      id: "page-role-component-table-permission-activate",
                    })}
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.permissionsModel
                  .filter(item => item.type === "operation")
                  .map((permission, index) => (
                    <tr
                      key={`tr-${index}`}
                      style={{ textAlign: "center", height: 45 }}
                    >
                      <td style={{ textAlign: "left", paddingLeft: 50 }}>
                        {this.props.intl.formatMessage({
                          id: permission.title,
                        })}
                      </td>
                      {permission.permissions.map(item => (
                        <td key={item.type}>
                          {this.getCheckboxToPermissionValue(item)}
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </table>
          </Row>
          <Row className="kpi-content">
            <table>
              <thead>
                <tr className="kpi-title" style={{ height: 30 }}>
                  <th style={{ textAlign: "left" }}>
                    {this.props.intl.formatMessage({
                      id: "page-role-component-table-consume",
                    })}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ height: 35 }}>
                  {this.props.permissionsModel
                    .find(item => item.type === "consume")
                    .permissions.map((permission, index) => (
                      <td
                        style={{ textAlign: "left", paddingLeft: 10 }}
                        key={`tr-consume-${index}`}
                      >
                        <span style={{ marginRight: 5 }}>
                          {this.getCheckboxToPermissionValue(
                            permission,
                            permission.title
                          )}
                        </span>
                      </td>
                    ))}
                </tr>
              </tbody>
            </table>
          </Row>
          <Row className="kpi-content">
            <div className="kpi-title">
              {this.props.intl.formatMessage({ id: "models-ingestions" })}
            </div>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 10,
                paddingLeft: 10,
              }}
            >
              <Col>
                {this.props.intl.formatMessage({
                  id: "page-role-component-form-ingest-select-title",
                })}
              </Col>
              <Col span={10} style={{ marginTop: 40 }}>
                <Form.Item>
                  {/* <FormControl>
                    <Autocomplete
                      multiple
                      // id="fixed-tags-demo"
                      placeholder={this.props.intl.formatMessage({
                        id:
                          "page-role-component-form-ingest-select-placeholder",
                      })}
                      value={this.state.currentIngestsSelected}
                      onChange={(evt, value) =>
                        console.log('---------', value, option, '--')
                        // this.onChangeIngestToRole(value, option)
                      }
                      options={[
                        {
                          key: this.props.intl.formatMessage({id: "page-role-component-form-ingest-select-all"}),
                          value:"*"
                        },
                        {
                          key: this.props.intl.formatMessage({id: "page-role-component-form-ingest-select-current"}),
                          value: "select-available",
                          disabled: this.state.disabledOptions
                        },
                        ...(this.props.allIngests
                          ? this.props.allIngests.map(d => (
                            {
                              disabled: this.state.disabledOptions,
                              key: d,
                              value: d
                             }
                          ))
                          : [])
                      ]}
                      getOptionLabel={option => option.key}
                      renderTags={(tagValue, getTagProps) =>
                        { console.log(tagValue)
                          console.log(getTagProps(0))
                          return tagValue.map((option, index) => (
                          <Chip
                            label={option}
                            {...getTagProps({ index })}
                            // disabled={fixedOptions.indexOf(option) !== -1}
                          />
                        ))}
                      }
                      style={{ width: 500 }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label={this.props.intl.formatMessage({
                            id: "page-role-component-form-ingest-select-placeholder",
                          })}
                          variant="outlined"
                          placeholder="Favorites"
                        />
                      )}
                    /> */}

                  <Select
                    className="knolar-user-input"
                    showSearch={true}
                    mode="multiple"
                    placeholder={this.props.intl.formatMessage({
                      id: "page-role-component-form-ingest-select-placeholder",
                    })}
                    value={this.state.currentIngestsSelected}
                    loading={
                      this.props.allIngests &&
                      this.props.allIngests.length === 0
                    }
                    name="select-ingest"
                    filterOption={(inputValue, option) =>
                      option.props.children
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onChange={(value, option) =>
                      this.onChangeIngestToRole(value, option)
                    }
                    onSelect={(value, option) =>
                      this.selectIngestToRole(value, option)
                    }
                  >
                    <Option key="select-all" value="*">
                      {this.props.intl.formatMessage({
                        id: "page-role-component-form-ingest-select-all",
                      })}
                    </Option>
                    <Option
                      key="select-available"
                      value="select-available"
                      disabled={this.state.disabledOptions}
                    >
                      {this.props.intl.formatMessage({
                        id: "page-role-component-form-ingest-select-current",
                      })}
                    </Option>
                    {this.props.allIngests
                      ? this.props.allIngests.map(d => (
                          <Option
                            disabled={this.state.disabledOptions}
                            key={d}
                            value={d}
                          >
                            {d}
                          </Option>
                        ))
                      : null}
                  </Select>
                  {/* </FormControl> */}
                </Form.Item>
              </Col>
            </Col>
          </Row>
        </Form>
      </div>
    )
  }
}
export default injectIntl(Form.create({ name: FORM_NAME })(PermissionsForm))
